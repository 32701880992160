import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavbarAlt = () => {
  return (
    <Wrapper className='navbar'>
      <div className='container-md alt-menu'>
        <Link to='/' className='navbar-brand fw-bold fs-3 main-heading'>
          TokenFaucets
        </Link>

        <div className='d-flex link-menu'>
          <ul className='navbar-nav'>
            <li className='nav-item fw-bold fs-5'>
              <Link className='nav-link active' to='/about'>
                About
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  background-color: #fffaf0;

  @media screen and (max-width: 768px) {
    .alt-menu {
      /* flex-direction: column; */
    }
    .link-menu {
      display: none;
    }
    .nav-link {
      font-size: 1rem;
    }
  }
`;
export default NavbarAlt;
