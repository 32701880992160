import React from "react";

const Hero = () => {
  return (
    <>
      <div className='pt-md-5 px-4 pt-4 pb-1 text-center'>
        {/* <img
          className='d-block mx-auto mb-4'
          src='https://images.rawpixel.com/image_png_400/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjkzNC1udW5ueS0xNy5wbmc.png'
          alt=''
          width='72'
          height='57'
        /> */}
        <h1 className='fw-bold main-heading'>Token Faucets</h1>
        <div className='col-lg-6 mx-auto'>
          <p className='lead mb-4 mx-auto hero-text'>
            Find free faucets for your favorite tokens, blockchains, and test
            networks.
          </p>
        </div>
      </div>
    </>
  );
};

export default Hero;
