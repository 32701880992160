import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useFaucetContext } from "../context/faucet_context";
import { Error, NavbarMain, Footer, Loading } from "../components";
import { FaChevronLeft } from "react-icons/fa";
import styled from "styled-components";

const SingleFaucet = (props) => {
  const {
    fetchSingleFaucet,
    isLoading,
    current_faucet,
    theme,
  } = useFaucetContext();
  const { faucetName } = useParams();
  useEffect(() => {
    document.title =
      faucetName.charAt(0).toUpperCase() +
      faucetName.slice(1).replace(/-/g, " ");
  }, []);
  useEffect(() => {
    fetchSingleFaucet(faucetName);
  }, []);

  if (current_faucet.length < 1) {
    return (
      <Wrapper className={theme === "dark" ? "dark-theme" : "light-theme"}>
        <NavbarMain />
        <div className='container-md px-4 mt-4 main-content'>
          <div className='row gy-3 pt-3'>
            <div className='col-sm-12 col-md-6 d-flex mx-auto mt-5'>
              <Error />
            </div>
          </div>
          <div className='row px-3'>
            <div className='col-12 d-grid justify-content-center'>
              <div className='mt-5 mx-auto'>
                <Link to='/'>
                  <button className='btn main-links-btn'>
                    <FaChevronLeft /> &nbsp; Back to all faucets
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </Wrapper>
    );
  }
  if (isLoading) {
    return (
      <Wrapper className={theme === "dark" ? "dark-theme" : "light-theme"}>
        <NavbarMain />
        <div className='container-md px-4 mt-4'>
          <div className='row gy-3 pt-3'>
            <div className='col-sm-12 col-md-6 d-flex'>
              <Loading />
            </div>
          </div>
        </div>
        <Footer />
      </Wrapper>
    );
  }
  return (
    <Wrapper className={theme === "dark" ? "dark-theme" : "light-theme"}>
      <NavbarMain />
      <div className='main-content'>
        <div className='container-md px-4'>
          <div className='row gy-3 pt-3'>
            <div className='col-sm-12 col-md-6 d-flex flex-column mx-auto justify-content-center content-start'>
              <div>
                <img
                  src={current_faucet[0].image}
                  alt={current_faucet[0].name}
                  className='rounded-5 mb-4 mt-2 d-block mx-auto'
                />
              </div>
              <h1 className='text-center mt-2 text-uppercase ps-3 fs-1'>
                {current_faucet[0].name} Token Faucet
              </h1>
              <p className='mx-auto'>{current_faucet[0].description}</p>
            </div>
          </div>
          <div className='row gy-3 pt-3'>
            <div className='col-sm-12 col-md-6 mx-auto'>
              <div className='row'>
                <div className='col-4 text-center'>
                  <p>
                    <strong>Chain ID:</strong>{" "}
                    <span className='badge rounded-pill chain-id-box info-pill'>
                      {current_faucet[0].chainID}
                    </span>
                  </p>
                </div>
                <div className='col-4 text-center'>
                  <p>
                    <strong>Currency:</strong>{" "}
                    <span className='badge rounded-pill currency-box info-pill'>
                      {current_faucet[0].token}
                    </span>
                  </p>
                </div>
                <div className='col-4 text-center'>
                  <p>
                    <strong>Type:</strong>{" "}
                    <span
                      className={`${
                        current_faucet[0].testnet === "yes"
                          ? "badge rounded-pill type-box info-pill"
                          : "badge rounded-pill type-box info-pill"
                      }`}
                    >
                      {current_faucet[0].testnet === "yes"
                        ? "Testnet"
                        : "Mainnet"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row gy-3 pt-3'>
            <div className='col-sm-12 col-md-6 mx-auto'>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Source</th>
                    <th scope='col'>Account Rquired?</th>

                    <th scope='col'>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {current_faucet[0].faucets.map((faucet, faucetIndex) => {
                    return (
                      <tr key={faucetIndex}>
                        <th scope='row'>{faucetIndex + 1}</th>
                        <td>
                          {faucet.website.slice(-1).toString() === "/"
                            ? faucet.website.slice(0, -1).toLowerCase()
                            : faucet.website.toLowerCase()}
                        </td>
                        <td>{faucet.working}</td>

                        <td>
                          <a
                            href={faucet.url}
                            className='visit-link'
                            target='_blank'
                            rel='nofollow noreferrer noopener'
                          >
                            Visit
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='row gy-3 px-3'>
            <div className='col-12 d-grid justify-content-center'>
              <div className='mt-5 mx-auto'>
                <Link to='/'>
                  <button className='btn main-links-btn'>
                    <FaChevronLeft /> &nbsp; Back to all faucets
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: var(--background-color);
  position: relative;
  min-height: 100vh;
  .main-content {
    min-height: calc(100vh - 60px);
    padding-bottom: 60px;
  }
  .content-start {
    margin-top: 4rem;
  }
  h1,
  p,
  th,
  td {
    color: var(--textColor);
  }
  .main-links-btn {
    color: var(--textColor);
  }
`;
export default SingleFaucet;
