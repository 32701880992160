import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Hero,
  SearchBox,
  TokenContainer,
  Footer,
  NavbarMain,
} from "../components";
import { useFaucetContext } from "../context/faucet_context";

const Home = (props) => {
  const { theme } = useFaucetContext();

  useEffect(() => {
    document.title = props.title || "TokenFaucets.com";
  }, []);
  return (
    <Wrapper className={theme === "dark" ? "dark-theme" : "light-theme"}>
      <NavbarMain />
      <div className='main-content'>
        <Hero />
        <SearchBox />
        <TokenContainer />
      </div>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: var(--background-color) !important;
  position: relative;
  /* min-height: 100vh; */
  .main-content {
    min-height: calc(100vh - 60px);
    padding-bottom: 60px;
  }
`;

export default Home;
