import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <Wrapper className='container-fluid d-flex align-items-end justify-content-center'>
      <div className='row'>
        <div className='col-12'>
          {/* <div className='d-flex align-items-end justify-content-center'> */}
          <h4>Created by WebVerge</h4>
          {/* </div> */}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  background-color: #000;
  margin-top: 4rem;
  bottom: 0px;
  width: 100%;
  height: 60px;
  h4 {
    font-size: 0.75rem;
    color: #fff;
    font-weight: bold;
  }
`;
export default Footer;
