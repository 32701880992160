const faucet_reducer = (state, action) => {
  if (action.type === "GET_FAUCETS") {
    return {
      ...state,
      all_faucets: action.payload,
      filtered_faucets: action.payload,
    };
  }
  if (action.type === "SEARCH_BOX_QUERY") {
    return {
      ...state,
      search_query: action.payload,
    };
  }
  if (action.type === "START_LOADING") {
    return { ...state, isLoading: true };
  }

  if (action.type === "FETCH_SINGLE_FAUCET") {
    // console.log(state.all_faucets);
    let temp_single_faucet = [...state.all_faucets];
    temp_single_faucet = temp_single_faucet.filter((faucet) => {
      // console.log(faucet.name);
      return (
        faucet.name.toLowerCase().replace(/ /g, "-") + "-faucet" ===
        action.payload.toLowerCase()
      );
    });

    return {
      ...state,
      current_faucet: temp_single_faucet,
      search_query: "",
      isLoading: false,
    };
  }
  if (action.type === "SEARCH_FAUCETS") {
    let temp_faucets = [...state.all_faucets];
    temp_faucets = temp_faucets.filter((faucet) => {
      return (
        faucet.name.toLowerCase().includes(state.search_query.toLowerCase()) ||
        faucet.name
          .toLowerCase()
          .startsWith(state.search_query.toLowerCase()) ||
        faucet.chainID === parseInt(state.search_query) ||
        faucet.token.toLowerCase().includes(state.search_query.toLowerCase())
      );
    });

    return { ...state, filtered_faucets: temp_faucets };
  }

  if (action.type === "CHANGE_THEME") {
    console.log(action.payload);
    let temp_theme;
    if (action.payload === "light") {
      temp_theme = "dark";
      localStorage.setItem("current_theme", temp_theme);
    } else {
      temp_theme = "light";
      localStorage.setItem("current_theme", temp_theme);
    }
    return { ...state, theme: temp_theme };
  }

  throw new Error("No matching action found");
};

export default faucet_reducer;
