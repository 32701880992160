import React from "react";
import { FaEthereum } from "react-icons/fa";
import FaucetLinks from "./FaucetLinks";
import { Link } from "react-router-dom";

import styled from "styled-components";

const SingleToken = ({ name, chainID, faucets, image, token }) => {
  return (
    <Wrapper className='container p-3 main-box'>
      <div className='row px-4 py-3'>
        <div className='col-12 text-center'>
          {(image && (
            <img src={image} alt={name} className='rounded-5 mb-3 mt-2' />
          )) || <FaEthereum />}
          <h3 className='d-flex justify-content-center align-items-center'>
            <span className='ps-3 fs-4 text-uppercase fw-bold'>{name}</span>
          </h3>
        </div>
      </div>
      <div className='row px-4'>
        <div className='col-4'>
          <p className='faucet-details'>
            Chain ID
            <br />
            <strong>{chainID}</strong>
          </p>
        </div>
        <div className='col-4'>
          <p className='faucet-details'>
            Total Faucets
            <br />
            <strong>{faucets.length}</strong>
          </p>
        </div>
        <div className='col-4'>
          <p className='faucet-details'>
            Currency
            <br />
            <strong>{token}</strong>
          </p>
        </div>
      </div>
      <div className='row px-2'>
        <div className='col-12'>
          <ul className='list-group faucet-link-data'>
            {faucets.slice(0, 2).map((faucet, faucetIndex) => {
              return <FaucetLinks key={faucetIndex} {...faucet} />;
            })}
          </ul>
        </div>
      </div>
      <div className='row px-2'>
        <Link to={`/${name.toLowerCase().replace(/ /g, "-")}-faucet`}>
          <div className='col-12 d-grid mt-3'>
            <button className='btn main-links-btn'>View All</button>
          </div>
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: var(--tokenBoxBg);
  border: 3px solid var(--singleTokenBorder);
  box-shadow: 8px 8px 0px var(--singleTokenBorder);
  border-radius: 25px;
  :hover {
    box-shadow: 8px 8px 0px #545454;
    border: 3px solid #545454;
  }
  .faucet-details {
    text-align: center;
    font-size: 90%;
    color: var(--textColor);
  }
  /* box-shadow: 10px 10px var(--grey-100), 10px 10px 0px 1px #000; */

  .faucet-link-data {
    border-radius: 20px;
    color: var(--textColor);
  }
`;
export default SingleToken;
