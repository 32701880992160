import React, { useContext, useEffect, useReducer } from "react";
import reducer from "../reducers/faucet_reducer";
import { products } from "../utils/products";
import { getCurrentTheme } from "../utils/localStorage";

console.log(`getcurrentmode is ${getCurrentTheme()}`);
const initialState = {
  theme: getCurrentTheme(),
  isLoading: false,
  all_faucets: [],
  filtered_faucets: [],
  search_query: "",
  current_faucet: [],
};

const FaucetContext = React.createContext();

export const FaucetProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchFaucets = () => {
    dispatch({ type: "GET_FAUCETS", payload: products });
  };
  const fetchFilteredFaucets = () => {
    dispatch({ type: "FETCH_FILTERED_FAUCETS" });
  };
  const fetchSingleFaucet = (name) => {
    dispatch({ type: "START_LOADING" });
    dispatch({ type: "GET_FAUCETS", payload: products });
    dispatch({ type: "FETCH_SINGLE_FAUCET", payload: name });
  };
  const searchThroughFaucets = () => {
    dispatch({ type: "SEARCH_FAUCETS" });
  };

  const searchFaucets = (e) => {
    dispatch({ type: "SEARCH_BOX_QUERY", payload: e.target.value });
    searchThroughFaucets();
  };

  const changeTheme = () => {
    dispatch({ type: "CHANGE_THEME", payload: state.theme });
  };

  useEffect(() => {
    fetchFaucets();
  }, []);

  // useEffect(() => {
  //   searchThroughFaucets();
  // }, [state.search_query]);
  return (
    <FaucetContext.Provider
      value={{
        ...state,
        searchFaucets,
        fetchSingleFaucet,
        fetchFaucets,
        fetchFilteredFaucets,
        searchThroughFaucets,
        changeTheme,
      }}
    >
      {children}
    </FaucetContext.Provider>
  );
};

export const useFaucetContext = () => {
  return useContext(FaucetContext);
};
