import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
// import $ from "jquery";
// import Popper from "popper.js";
import React from "react";
import { createRoot } from "react-dom/client";
import { FaucetProvider } from "./context/faucet_context";

import "normalize.css";
import "./assets/css/index.css";

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <FaucetProvider>
    <App tab='home' />
  </FaucetProvider>
);
