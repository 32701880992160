import React from "react";
import styled from "styled-components";
import SingleToken from "./SingleToken";
import Error from "./Error";

import { useFaucetContext } from "../context/faucet_context";

const TokenContainer = () => {
  const { filtered_faucets } = useFaucetContext();

  if (filtered_faucets.length < 1) {
    return (
      <Wrapper className='container-md px-3 mt-5'>
        <div className='row py-5'>
          <div className='col-12 d-flex justify-content-center'>
            <Error />
          </div>
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper className='container-md px-3 mt-5'>
      <div className='row gy-4 pt-3'>
        {filtered_faucets.map((dataItem) => {
          return (
            <div className='col-sm-2 col-md-6 col-lg-4' key={dataItem.chainID}>
              <SingleToken {...dataItem} />
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .token-column {
    background-color: #fff;
  }
  margin-bottom: 8rem;
  /* .pagination-component {
    position: relative;
    top: 4rem;
    margin-bottom: 12rem;
  } */
`;
export default TokenContainer;
