import React, { useEffect } from "react";
import { useFaucetContext } from "../context/faucet_context";
import { NavbarMain, Footer } from "../components";
import styled from "styled-components";

const About = (props) => {
  const { theme } = useFaucetContext();
  useEffect(() => {
    document.title = props.title || "TokenFaucets.com";
  }, [props.title]);
  return (
    <Wrapper className={theme === "dark" ? "dark-theme" : "light-theme"}>
      <NavbarMain />
      <div className='main-content'>
        <div className='container-md px-4'>
          <div className='row gy-3 pt-3'>
            <div className='col-sm-12 mx-auto text-center p-3'>
              <div className='main-text'>
                <h1>About</h1>
                <p>
                  A few weeks ago, when I wanted to find token faucets to test
                  out a couple of less popular blockchains, I was surprised by
                  the number of spam results clogging up the top of all search
                  engines.
                </p>
                <p>
                  So I decided to create this website to aggregate token faucets
                  from higher quality sources.
                </p>
                <p>
                  Each of these sources was personally tested and I removed
                  anything that might end up asking you to either sign-up for a
                  spammy service, or straight up scam your wallet.
                </p>
                <p>
                  Use this website to find free faucets for your favorite crypto
                  projects and testnets.
                </p>
                <p>
                  If you have any queries about this site or want to talk crypto
                  in general{" "}
                  <a
                    href='https://twitter.com/pjay_play'
                    target='_blank'
                    className='link-info'
                    rel='noreferrer noopener'
                  >
                    reach out to me on Twitter here.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: var(--background-color);
  position: relative;
  min-height: 100vh;
  .main-content {
    min-height: calc(100vh - 60px);
    padding-bottom: 60px;
  }
  .content-start {
    margin-top: 4rem;
  }
  h1,
  p,
  th,
  td {
    color: var(--textColor);
  }
  .main-text p {
    margin: 1.6rem auto;
    text-align: justify;
  }
`;
export default About;
