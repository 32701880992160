import ethereum from "../assets/images/logos/rsz_ethereum.webp";
import avax from "../assets/images/logos/rsz_avalanche.webp";
import aurora from "../assets/images/logos/rsz_aurora.webp";
import astar from "../assets/images/logos/rsz_astar.webp";
import bsc from "../assets/images/logos/rsz_binance.webp";
import arbitrum from "../assets/images/logos/rsz_arbitrum.webp";
import boba from "../assets/images/logos/rsz_boba.webp";
import celo from "../assets/images/logos/rsz_celo.webp";
import cronos from "../assets/images/logos/rsz_cronos.webp";
import evmos from "../assets/images/logos/rsz_evmos.webp";
import fantom from "../assets/images/logos/rsz_fantom.webp";
import fuse from "../assets/images/logos/rsz_fuse.webp";
import fusion from "../assets/images/logos/rsz_fusion.webp";
import harmony from "../assets/images/logos/rsz_harmony.webp";
import heco from "../assets/images/logos/rsz_heco.webp";
import hoo from "../assets/images/logos/rsz_hoo.webp";
import kava from "../assets/images/logos/rsz_kava.webp";
import klaytn from "../assets/images/logos/rsz_klaytn.webp";
import kucoin from "../assets/images/logos/rsz_kucoin.webp";
import metis from "../assets/images/logos/rsz_metis.webp";
import moonbeam from "../assets/images/logos/rsz_moonbeam.webp";
import moonriver from "../assets/images/logos/rsz_moonriver.webp";
import oasis from "../assets/images/logos/rsz_oasis.webp";
import optimism from "../assets/images/logos/rsz_optimism.webp";
import polygon from "../assets/images/logos/rsz_polygon.webp";
import rsk from "../assets/images/logos/rsz_rsk.webp";
import telos from "../assets/images/logos/rsz_telos.webp";
import theta from "../assets/images/logos/rsz_theta.webp";
import xdai from "../assets/images/logos/rsz_xdai.webp";
import shiden from "../assets/images/logos/rsz_shiden.webp";
import solana from "../assets/images/logos/Solana_logo.png";

export const products = [
  {
    name: "rinkeby ethereum testnet",
    description:
      "Rinkeby is an Ethereum testnet that's commonly used to test smart contracts before deployment to the mainnet (i.e. Ethereum network). Essentially, Rinkeby is a fork of the Ethereum mainnet and works exactly as the mainnet. The only difference is that gas is paid for in faux ETH that can be obtained from any of the Rinkeby token faucets listed below.",
    chainID: 4,
    token: "ETH",
    testnet: "no",
    image: ethereum,
    faucets: [
      {
        website: "Rinkeby.io",
        url: "https://faucet.rinkeby.io/",
        working: "no",
      },
      {
        website: "RinkebyFaucet",
        url: "https://rinkebyfaucet.com/",
        working: "no",
      },
      {
        website: "Chain.Link",
        url: "https://faucets.chain.link/",
        working: "no",
      },
      {
        website: "Paradigm.xyz",
        url: "https://faucet.paradigm.xyz/",
        working: "yes",
      },
    ],
  },
  {
    name: "ropsten ethereum testnet",
    description:
      "Ropsten is an Ethereum testnet that's used to test smart contracts before they can be deployed to the mainnet. Ropsten has the distinction of being the oldest Ethereum testnet. Ropsten faucets are useful for acquiring 'fake' ETH used on the testnet (i.e. they have no real value) for deploying and testing smart contracts before they are published on the mainnet.",
    chainID: 3,
    token: "ETH",
    image: ethereum,
    testnet: "yes",
    faucets: [
      {
        website: "egorfine.com",
        url: "https://faucet.egorfine.com/",
        working: "yes",
      },
      {
        website: "metamask.io/",
        url: "https://faucet.metamask.io/",
        working: "yes",
      },
      {
        website: "dimensions.network/",
        url: "https://faucet.dimensions.network/",
        working: "yes",
      },
      {
        website: "Moonborrow.com/",
        url: "https://moonborrow.com/",
        working: "yes",
      },
    ],
  },
  {
    name: "Goerli ethereum testnet",
    description:
      "Goerli is another Ethereum testnet that's arguably the most popular of all Ethereum testnets. The popularity of Goerli can be ascribed to the fact that it supports a much larger variety of node software than alternative testnets like Rinkeby or Kovan. Because of its widespread use, finding Goerli testnet ETH is usually not hard. Use of the many Goerli faucets listed below to get started.",
    chainID: 5,
    token: "ETH",
    image: ethereum,
    testnet: "yes",
    faucets: [
      {
        website: "GoerliFaucet.com",
        url: "https://goerlifaucet.com/",
        working: "yes",
      },
      {
        website: "Mudit.blog",
        url: "https://goerli-faucet.mudit.blog/",
        working: "yes",
      },
      {
        website: "Pk910.de",
        url: "https://goerli-faucet.pk910.de/",
        working: "yes",
      },
      {
        website: "Slock.it",
        url: "https://goerli-faucet.slock.it/",
        working: "yes",
      },
      {
        website: "paradigm.xyz",
        url: "https://faucet.paradigm.xyz/",
        working: "yes",
      },
    ],
  },
  {
    name: "Avalanche Fuji Testnet",
    description:
      "Avalanche (AVAX) is one of the most popular smart contract platforms with the ability to diversify workload across 'subnets'. Avalanche Fuji is the primary testnet on the Avalanche blockchain that is used to test smart contracts before they're deployed to the Avax mainnet. To effectively use Avalanche Fuji, however, you will need a faucet - use the Avalanche Fuji faucets listed below to get started.",
    chainID: 43113,
    image: avax,
    token: "Avax",
    testnet: "yes",
    faucets: [
      {
        website: "Avax.network",
        url: "https://faucet.avax.network/",
        working: "yes",
      },
      {
        website: "Chain.Link",
        url: "https://faucets.chain.link/",
        working: "yes",
      },
    ],
  },
  {
    name: "BSC Testnet",
    description:
      "BSC or Binance Smart Chain is an EVM (Ethereum Virtual Machine) compatible blockchain built and maintained by Binance. The BSC testnet is used as a test environment to test out dApps and smart contracts before they are deployed to the BSC mainnet. To use the testnet, however, you will need some test BNBs - use the BSC testnet faucets below to grab some. ",
    chainID: 97,
    image: bsc,
    token: "BNB",
    testnet: "yes",
    rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    faucets: [
      {
        website: "Binance.org",
        url: "https://testnet.binance.org/faucet-smart",
        working: "yes",
      },
      {
        website: "Testnet.net",
        url: "https://testnet.help/en/bnbfaucet/testnet",
        working: "yes",
      },
      {
        website: "Chain.Link",
        url: "https://faucets.chain.link/",
        working: "yes",
      },
    ],
  },
  {
    name: "Kovan Ethereum Testnet",
    description:
      "Kovan is yet another Ethereum testnet that uses 'Proof of Authority' (PoA) as a consensus mechanism. It can be used to deploy smart contracts before they are deployed to the Ethereum mainnet. Kovan is popular among developers because of its robust documentation and fast speed, thanks to the PoA consensus mechanism. Use the Kovan Ethereum faucets below to get ETH to test out the network or deploy your own smart contracts.",
    chainID: 42,
    image: ethereum,
    token: "KETH",
    testnet: "yes",
    rpc: "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    faucets: [
      {
        website: "Gitter.im",
        url: "https://gitter.im/kovan-testnet/faucet",
        working: "yes",
      },
      {
        website: "Kovan.network",
        url: "https://faucet.kovan.network/",
        working: "na",
      },
      {
        website: "Chain.Link",
        url: "https://faucets.chain.link/",
        working: "yes",
      },
    ],
  },
  {
    name: "Fantom Testnet",
    description:
      "Fantom is a smart contract blockchain that uses DAG - Directed Acyclic Graph - to achieve faster speeds and cheaper transactions. Fantom is EVM-compatible and uses the FTM token to pay for transactions. You can get test FTM for the Fantom testnet from the token faucets below.",
    chainID: 4002,
    image: fantom,
    token: "FTM",
    testnet: "yes",
    rpc: "https://rpc.testnet.fantom.network/",
    faucets: [
      {
        website: "Fantom.network",
        url: "https://faucet.fantom.network/",
        working: "yes",
      },
      {
        website: "Chain.Link",
        url: "https://faucets.chain.link/",
        working: "yes",
      },
    ],
  },
  {
    name: "Solana Testnet",
    description:
      "Solana is a popular public blockchain that uses an innovative 'proof of history' consensus mechanism. The Solana testnet is used by Solana core contributors to test new feature releases. None of the tokens on the Solana testnet are real and have no actual value. You can use the Solana testnet faucets below to acquire some of these testnet tokens to test out new features or deploy your own dApps.",
    chainID: "",
    token: "bSOL",
    image: solana,
    testnet: "yes",
    rpc: "",
    faucets: [
      {
        website: "SolanaTools.xyz",
        url: "https://solanatools.xyz/faucet/testnet.html",
        working: "yes",
      },
      {
        website: "Stakely.io",
        url: "https://stakely.io/en/faucet/solana-sol",
        working: "yes",
      },
      {
        website: "SolBlaze.org",
        url: "https://stake.solblaze.org/app/devnet",
        working: "yes",
      },
    ],
  },
  {
    name: "Polygon Mumbai Testnet",
    description:
      "EPolygon is one of the most popular Ethereum sidechains that leverages Ethereum's core security while offering drastically lower transaction costs. Polygon Mumbai is the primary testnet used for testing smart contracts and dApps on Polygon before they are deployed on the Polygon mainnet. To use the testnet, you will need some testnet Matic (Polygon's network token) which can be found on the Polygon Mumbai faucets below.",
    chainID: 80001,
    image: polygon,
    token: "MATIC",
    testnet: "yes",
    rpc: "https://rpc-mumbai.maticvigil.com",
    faucets: [
      {
        website: "Polygon.Technology",
        url: "https://faucet.polygon.technology/",
        working: "yes",
      },
      {
        website: "MumbaiFaucet.com",
        url: "https://mumbaifaucet.com/",
        working: "yes",
      },
      {
        website: "Chain.Link",
        url: "https://faucets.chain.link/",
        working: "yes",
      },
    ],
  },
  {
    name: "Harmony One Testnet",
    description:
      "Harmony One is a popular Layer-1 blockchain that's built on top of the Ethereum network and uses sharding and proo of stake (PoS) consensus mechanism to offer users scalability and speed. Before you can deploy contracts on the Harmony One mainnet, however, you will need to test them out. Use the Harmony One testnet faucets below to get testnet ONE (Harmony's currency) for testing out your dApps.",
    chainID: 1666700000,
    token: "ONE",
    image: harmony,
    testnet: "yes",
    rpc: "https://api.s0.b.hmny.io",
    faucets: [
      {
        website: "Pops.one",
        url: "https://faucet.pops.one/",
        working: "yes",
      },
      {
        website: "EasyNode.one",
        url: "http://dev.faucet.easynode.one/",
        working: "yes",
      },
      {
        website: "Chain.Link",
        url: "https://faucets.chain.link/",
        working: "yes",
      },
    ],
  },
  {
    name: "Cronos Testnet",
    description:
      "Cronos is an EVM compatible chain built by Crypto.com exchange. Unlike other EVM chains, Cronos also offers IBC (Inter-Blockchain Communications) compatibility that allows it to network with the Cosmos ecosystem. Cronos uses the CRO token as its primary network token. You can get tiny amounts of CRO from the Cronos token faucets listed below. Because of the low cost of transactions on this chain, even tiny amounts of CRO are enough to complete several transactions.",
    chainID: 338,
    image: cronos,
    token: "tCRO",
    testnet: "yes",
    rpc: "https://cronos-testnet-3.crypto.org:8545/",
    faucets: [
      {
        website: "Cronos.org",
        url: "https://cronos.org/faucet",
        working: "yes",
      },
    ],
  },
  {
    name: "Arbitrum Rinkeby Testnet",
    description:
      "Arbitrum is an ETH Layer-2 network that uses optimistic rollups to roll together multiple transactions before sending them to the Ethereum mainnet for final confirmation. This allows transactions on Arbitrum to cheaper and faster than the mainnet. To get test ETH on the Arbitrum network, you can get some test ETH on the Rinkeby network and bridge it over to Arbitrum. Use the Arbitrum Rinkeby faucet below to get started.",
    chainID: 421611,
    token: "AETH",
    image: arbitrum,
    testnet: "yes",
    rpc: "https://rinkeby.arbitrum.io/rpc",
    notes:
      "Get Rinkeby ETH on Ethereum testnet and bridge to Arbitrum using the Arbitrum bridge.",
    faucets: [
      {
        website: "Chain.Link",
        url: "https://faucets.chain.link/",
        working: "yes",
      },
      {
        website: "Rinkeby.io",
        url: "https://faucet.rinkeby.io/",
        working: "yes",
      },
    ],
  },
  {
    name: "Evmos Testnet",
    description:
      "Evmos is the bridge between the Ethereum and Cosmos ecosystems, offering compatibility with both EVM and IBC protocols. Evmos promises the interoperability of IBC with the developer friendliness of EVM chains. Use the Evmos testnet faucets below to get free testnet Evmos tokens for testing out your dApps on the Evmos chain.",
    chainID: 9000,
    token: "tEvmos",
    testnet: "yes",
    image: evmos,
    rpc: "https://eth.bd.evmos.dev:8545",
    notes: "",
    faucets: [
      {
        website: "Evmos.dev",
        url: "https://faucet.evmos.dev/",
        working: "yes",
      },
    ],
  },
  {
    name: "Evmos Mainnet",
    description:
      "Evmos is part of the IBC (Inter-Blockchain Communication) protocol developer under the Cosmos ecosystem. However, Evmos is also EVM-compatible, making it easy to build dApps or even port them directly from Ethereum. EVMOS is the native token of this blockchain. You can get small amounts of Evmos from the Evmos faucets shared below.",
    chainID: 9001,
    token: "Evmos",
    testnet: "no",
    image: evmos,
    rpc:
      "https://evmos-mainnet.gateway.pokt.network/v1/lb/627586ddea1b320039c95205",
    notes: "",
    faucets: [
      {
        website: "Evmos-Faucet",
        url: "https://evmos-faucet.netlify.app/",
        working: "yes",
      },
      {
        website: "Stakely.io",
        url: "https://stakely.io/en/faucet/evmos-evm",
        working: "yes",
      },
    ],
  },
  {
    name: "Astar Mainnet",
    description:
      "Astar, also known as Plasm, is the bridge between Ethereum and Polkadot ecosystems. Astar allows developers to deploy both EVM (Ethereum Virtual Machine) and WASM (WebAssembly) dApps, making it possible for developers across these two blockchains to benefit from each other's users. You can get small amounts of Astar to complete a few transactions from the Astar token faucets listed below.",
    chainID: 592,
    token: "ASTR",
    testnet: "no",
    image: astar,
    rpc: "https://rpc.astar.network:8545",
    notes: "",
    faucets: [
      {
        website: "As-Faucet",
        url: "https://www.as-faucet.xyz/en/astar#",
        working: "no",
      },
      {
        website: "Astar Network",
        url: "https://portal.astar.network/#/balance/wallet",
        working: "no",
      },
    ],
  },
  {
    name: "Aurora Mainnet",
    description:
      "Aurora is an EVM-compatible blockchain built on the NEAR protocol. Developers can quickly deploy their Ethereum dApps on the Aurora blockchain while also benefiting from NEAR's speed, low costs, and scalability. More importantly, Aurora's base currency is ETH. You can get some free Aurora ETH from the faucets listed below.",
    chainID: 1313161554,
    token: "ETH",
    testnet: "no",
    image: aurora,
    rpc: "https://mainnet.aurora.dev",
    notes: "",
    faucets: [
      {
        website: "Aurora.dev",
        url: "https://aurora.dev/faucet",
        working: "no",
      },
      {
        website: "Stakely",
        url: "https://stakely.io/en/faucet/aurora",
        working: "yes",
      },
    ],
  },
  {
    name: "Celo Testnet",
    description:
      "Celo is a mobile-first Proof-of-Stake (PoS) blockchain designed from the ground up to bring scale, speed, and relability to smartphone users. You can use the Celo testnet to test out your smart contracts before deploying them on the mainnet. Use the Celo testnet faucets below to grab free testnet Celo as well as testnet stablecoins (such as cUSD).",
    chainID: 42220,
    token: "CELO",
    testnet: "yes",
    image: celo,
    rpc: "wss://forno.celo.org/ws",
    notes: "",
    faucets: [
      {
        website: "Celo.org",
        url: "https://celo.org/developers/faucet",
        working: "yes",
      },
      {
        website: "Stakely",
        url: "https://stakely.io/en/faucet/celo-platform",
        working: "yes",
      },
    ],
  },
  {
    name: "Fusion Mainnet",
    description:
      "Fusion is a DeFi focused blockchain that uses the Hierarchical Hybrid Consensus Mechanism (HHCM) - a mix of multiple consensus algorithms - to offer fast, scalable, and secure transactions. Fusion was built by the same team behind BitSE, and with support from the teams that led QTUM and VeChain. FSN is the native currency of this blockchain. You can use the Fusion token faucets below to get small amounts of FSN necessary to complete transactions on the Fusion mainnet.",
    chainID: 32659,
    token: "FSN",
    testnet: "no",
    image: fusion,
    rpc: "https://mainnet.anyswap.exchange",
    notes: "",
    faucets: [
      {
        website: "FSN.dev",
        url: "https://fsn.dev/faucet/",
        working: "no",
      },
    ],
  },
  {
    name: "Fuse Mainnet",
    description:
      "Ethereum is a proof-of-stake (soon to be proof-of-work) blockchain designed from the ground up to support smart contracts. As the second largest blockchain after market valuation, and the largest by users, Ethereum facuets are in high-demand. The high cost of running a faucet, however, means that you will have to sign-up for websites or complete tasks in order to get access to free coins from an Ethereum faucet.",
    chainID: 122,
    token: "FUSE",
    image: fuse,
    testnet: "no",
    rpc: "https://rpc.fuse.io",
    notes: "",
    faucets: [
      {
        website: "Stakely",
        url: "https://stakely.io/en/faucet/fuse-blockchain",
        working: "yes",
      },
    ],
  },
  {
    name: "Shiden Mainnet",
    description:
      "Shiden is 'parachain' built on top of the Kusama (KSM) network, which itself is a  test network for Polkadot. Shiden is multi-chain as a core design philosophy and currently integrates with Kusama, Cosmos, and the Secret Network. SDN is the native currency of the Shiden chain. You can get small amounts of SDN from the Shiden token faucets listed below.",
    chainID: 336,
    token: "SDN",
    image: shiden,
    testnet: "no",
    rpc: "https://www.as-faucet.xyz/en/shiden#",
    notes: "",
    faucets: [
      {
        website: "As-Faucet",
        url: "https://www.as-faucet.xyz/en/shiden#",
        working: "no",
      },
    ],
  },
  {
    name: "Heco Testnet",
    description:
      "Heco, or Huobi Eco Chain, is an EVM-compliant chain built and maintained by Huobi Exchange. This chain uses HT (Huobi Token) as its native currency. Heco maintains a testnet to help developers try out their dApps before taking them live. You can get some free testnet HT using the Heco token faucet below. ",
    chainID: 256,
    token: "HT",
    testnet: "yes",
    image: heco,
    rpc: "https://http-testnet.hecochain.com",
    notes: "",
    faucets: [
      {
        website: "HecoChain.com",
        url: "https://scan-testnet.hecochain.com/faucet",
        working: "no",
      },
    ],
  },
  {
    name: "Hoo Testnet",
    description:
      "HOO chain is another EVM-compliant chain built by Hoo exchange. Like Binance's BSC and Huobi's HECO, the HOO chain promises cheap transactions and fast resolution times. The native currency of the chain is HOO. You can get some free testnet HOO from the token faucets listed below. ",
    chainID: 170,
    token: "HOO",
    testnet: "yes",
    image: hoo,
    rpc: "https://http-testnet.hoosmartchain.com/",
    notes: "",
    faucets: [
      {
        website: "HSCScan.com",
        url: "https://testnet.hscscan.com/hydrant",
        working: "no",
      },
    ],
  },
  {
    name: "Kava Mainnet",
    description:
      "Kava is a Layer-1 blockchain with a unique architecture that offers interoperability across both Ethereum and Cosmos ecosystems. Developers can choose to use either/both Cosmos SDK and Ethereum Virtual Machine. This gives Kava the speed of Cosmos with the developer network strength of Ethereum. The native currency of the chain is Kava. You can get enough Kava to complete a few transactions on the Kava token faucets listed below.",
    chainID: 2222,
    token: "KAVA",
    testnet: "no",
    image: kava,
    rpc: "https://evm.kava.io",
    notes: "",
    faucets: [
      {
        website: "Kava.io",
        url: "https://faucet.kava.io/",
        working: "no",
      },
    ],
  },
  {
    name: "Klaytn Mainnet",
    description:
      "Klaytn is a blockchain built by KaKao, South Korea's most popular messaging app. Klaytn bills itself as a fast and cheap blockchain built to facilitate creators. The native token of this chain is KLAY. You can get small amounts of KLAY to complete a few transactions from the Klaytn faucets listed below.",
    chainID: 8217,
    token: "KLAY",
    testnet: "no",
    image: klaytn,
    rpc: "https://cypress.fandom.finance/archive",
    notes: "",
    faucets: [
      {
        website: "klaytn.foundation",
        url: "https://baobab.wallet.klaytn.foundation/faucet",
        working: "no",
      },
      {
        website: "ooju.xyz",
        url: "https://ooju.xyz/en/klay-faucet",
        working: "no",
      },
    ],
  },
  {
    name: "Kucoin Testnet",
    description:
      "Kucoin is one of the largest exchanges in the world and the leading crypto exchange in South Korea. It runs a Layer-1 blockchain called KCS (with a native token of the same name). This chain is EVM-compatible and offers fast transaction speeds. You can get token amounts of KCS from the token faucets listed below.",
    chainID: 322,
    token: "KCS",
    testnet: "yes",
    image: kucoin,
    rpc: "https://rpc-testnet.kcc.network/",
    notes: "",
    faucets: [
      {
        website: "kcc.network",
        url: "https://faucet-testnet.kcc.network/",
        working: "no",
      },
    ],
  },
  {
    name: "Metis Stardust Testnet",
    description:
      "Metis is an Ethereum-based Layer-2 scaling solution that bundles and processes transactions off-chain in order to speed up transactions while also lowering costs. Metis maintains the Stardust testnet for developers. You can use the Metis testnet faucets below to get test Metis tokens for deploying or testing out your dApps.",
    chainID: 588,
    token: "METIS",
    testnet: "yes",
    image: metis,
    rpc: "https://stardust.metis.io/?owner=588",
    notes: "",
    faucets: [
      {
        website: "metis.io",
        url: "https://rinkeby-faucet.metis.io/",
        working: "no",
      },
    ],
  },
  {
    name: "Moonbeam",
    description:
      "Moonbeam is Polkadot parachain that offers EVM-compatible smart contracts. Think of it as a bridge between Ethereum and Polkadot. The native currency of the Moonbeam chain is GLMR. You can get free GLMR from the token faucets listed below. Even 0.01 GLMR should be enough for a few transactions.",
    chainID: 1284,
    token: "GLMR",
    testnet: "no",
    image: moonbeam,
    rpc: "https://moonbeam.public.blastapi.io",
    notes: "",
    faucets: [
      {
        website: "glmr.supply",
        url: "https://glmr.supply/",
        working: "no",
      },
      {
        website: "stellaswap",
        url: "https://app.stellaswap.com/bridge/gas-swap",
        working: "no",
      },
    ],
  },
  {
    name: "Moonriver",
    description:
      "Moonriver is a Kusama parachain that offers EVM-compatible smart contracts. Its the Kusama equivalent of Moonbeam on the Polkadot chain. Moonriver's native token is Movr which is used to pay for transactions. You can get small amounts of MOVR from the Moonriver token faucets below - just enough to complete a few transactions and bring your assets on-chain.",
    chainID: 1285,
    token: "MOVR",
    testnet: "no",
    image: moonriver,
    rpc: "https://moonriver.public.blastapi.io",
    notes: "",
    faucets: [
      {
        website: "movr.supply",
        url: "https://movr.supply/",
        working: "no",
      },
      {
        website: "stakely",
        url: "https://stakely.io/en/faucet/moonriver-movr",
        working: "yes",
      },
      {
        website: "solarbeam",
        url: "https://app.solarbeam.io/bridge/gas-swap",
        working: "no",
      },
    ],
  },
  {
    name: "Optimism Kovan",
    description:
      "Optimism is an Ethereum Layer-2 scaling solution that uses optimistic rollups to process transactions faster and cheaper. Optimism has a testnet on the Kovan network, called Optimism Kovan. This is a test chain that uses test ETH as the native currency. You can get free test ETH for trying out dApps on Optimism Kovan from the token faucets listed below. ",
    chainID: 69,
    token: "KOR",
    image: optimism,
    testnet: "yes",
    rpc: "https://kovan.optimism.io/",
    notes: "",
    faucets: [
      {
        website: "OptimismFaucet",
        url: "https://optimismfaucet.xyz/",
        working: "yes",
      },
      {
        website: "OptiFaucet",
        url: "https://kovan.optifaucet.com/",
        working: "no",
      },
    ],
  },
  {
    name: "RSK Testnet",
    description:
      "RSK is Bitcoin sidechain, built with the goal of bringing smart contract capability to the Bitcoin network. Think of it as a separate leg of Bitcoin that turns the chain into a full-fledged smart contract platform. The native currency of RSK is RBTC. To test out dApps on the RSK testnet, you will need test RBTC (i.e. tRBTC). You can get token amounts of tRBTC from the faucets below.",
    chainID: 31,
    token: "tRBTC",
    testnet: "yes",
    image: rsk,
    rpc: "https://public-node.testnet.rsk.co",
    notes: "",
    faucets: [
      {
        website: "rsk.co",
        url: "https://faucet.rsk.co/",
        working: "no",
      },
    ],
  },
  {
    name: "Telos Mainnet",
    description:
      "Telos is a Layer-1 EVM-compatible blockchain built to create wider adoption of EVM-compatible smart contracts. A core value proposition of Telos is that it supports smart contracts written in C++, and not just Solidity. Telos uses TLOS as its native currency. You can get token amounts of TLOS - enough to complete a few transactions - from the faucets listed below. ",
    chainID: 40,
    token: "TLOS",
    image: telos,
    testnet: "no",
    rpc: "https://rpc.telosarabia.net/evm",
    notes: "",
    faucets: [
      {
        website: "stakely",
        url: "https://stakely.io/en/faucet/telos-evm",
        working: "yes",
      },
    ],
  },
  {
    name: "Gnosis Chain (xDAI)",
    description:
      "Gnosis Chain, formerly called xDAI, is an EVM-compatible chain. Gnosis uses xDai - a stablecoin pegged to 1USD - as the native transaction token. It also uses GNO to pay stakers for rewards. To test out the chain, you will need small amounts of xDAI - use the Gnosis faucets below to get started.",
    chainID: 100,
    token: "xDAI",
    image: xdai,
    testnet: "no",
    rpc: "https://rpc.ankr.com/gnosis",
    notes: "",
    faucets: [
      {
        website: "gimlu.com",
        url: "https://www.gimlu.com/faucet",
        working: "no",
      },
      {
        website: "xdai-faucet",
        url: "https://xdai-faucet.top/",
        working: "no",
      },
      {
        website: "stakely",
        url: "https://stakely.io/en/faucet/gnosis-chain-xdai",
        working: "yes",
      },
    ],
  },
];
