import React from "react";
import styled from "styled-components";
const Error = () => {
  return (
    <Wrapper>
      <h4>No matching faucet found</h4>
      <p style={{}}>Try searching for something else...</p>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  h4,
  p {
    color: var(--textColor) !important;
  }
`;
export default Error;
