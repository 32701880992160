import React, { useEffect } from "react";
import { useFaucetContext } from "../context/faucet_context";
import { NavbarMain, Footer } from "../components";
import styled from "styled-components";
import { Link } from "react-router-dom";
const About = (props) => {
  const { theme } = useFaucetContext();
  useEffect(() => {
    document.title = props.title || "TokenFaucets.com";
  }, [props.title]);
  return (
    <Wrapper className={theme === "dark" ? "dark-theme" : "light-theme"}>
      <NavbarMain />
      <div className='main-content'>
        <div className='container-md px-4'>
          <div className='row gy-3 pt-3'>
            <div className='col-sm-12 mx-auto text-center p-3'>
              <div className='main-text'>
                <h1>Error!</h1>
                <p>We couldn't find the page you were looking for</p>
                <p>
                  <Link to='/'>Head back home</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: var(--background-color);
  position: relative;
  min-height: 100vh;
  .main-content {
    min-height: calc(100vh - 60px);
    padding-bottom: 60px;
  }
  .content-start {
    margin-top: 4rem;
  }
  h1,
  p,
  th,
  td {
    color: var(--textColor);
  }
  .main-text p {
    margin: 1.6rem auto;
    text-align: justify;
  }
`;
export default About;
