import React from "react";
import styled from "styled-components";

const FaucetLinks = ({ website, url, working }) => {
  return (
    <Wrapper className='list-group-item d-flex justify-content-between align-items-center fs-6'>
      {website}
      <a
        href={url}
        target='_blank'
        rel='noopener noreferrer nofollow'
        className='visit-link'
      >
        Visit
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  background-color: var(--tokenBoxBg);
  border-color: #999;
  color: var(--textColor);
`;

export default FaucetLinks;
