import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Error, Home, About, SingleFaucet } from "./pages";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <Home title='Home - Search Crypto Token Faucets for Top Blockchains' />
          }
        />
        <Route path='about' element={<About title='About' />} />
        <Route path=':faucetName' element={<SingleFaucet />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
