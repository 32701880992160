import React from "react";
import { useFaucetContext } from "../context/faucet_context";

import { FaBars, FaMoon } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavbarMain = () => {
  const { theme, changeTheme } = useFaucetContext();
  return (
    <Wrapper className='navbar navbar-expand-sm lg'>
      <div className='container-md'>
        <Link
          to='/'
          className='navbar-brand d-flex fw-bold justify-content-sm-end align-items-center pt-3'
          href='#'
        >
          {/* <img
            src={Clown}
            className='d-inline-block align-text-center'
            height='32'
            width='32'
          /> */}
          &nbsp; <h2 className='logo-text'>TokenFaucet.com</h2>
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon text-white align-items-center'>
            <FaBars />
          </span>
        </button>
        <div
          className='collapse navbar-collapse justify-content-end align-items-center'
          id='navbarNav'
        >
          <ul className='navbar-nav main-menu d-flex align-items-center gap-3'>
            <li className='nav-item'>
              <div className='form-check form-switch pt-2'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckChecked'
                  checked={theme === "dark" ? true : false}
                  onChange={() => changeTheme()}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexSwitchCheckChecked'
                >
                  <FaMoon />
                </label>
              </div>
            </li>
            <li className='nav-item'>
              <Link
                to='/about'
                className='nav-link active'
                aria-current='page'
                href='#'
              >
                About
              </Link>
            </li>

            {/* <li className='nav-item'>
              <a className='nav-link' href='#'>
                Contact
              </a>
            </li>
            <li className='nav-item'>
              <Link to='/about' className='nav-link'>
                About
              </Link>
            </li> */}
          </ul>
        </div>{" "}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  .main-menu a {
    color: var(--textColor);
    font-weight: bold;
  }
  .main-menu a:hover {
    color: var(--menuLinkHover);
  }
  .main-menu a:visited {
    color: var(--menuLinkVisited);
  }
  .main-menu .dropdown-menu a {
    color: var(--textColor);
    font-weight: 300;
    font-size: 90%;
  }
  .form-check-label {
    color: var(--textColor);
    position: relative;
    top: -4px;
    padding-right: 10px;
  }
  @media (max-width: 768px) {
    .main-menu a {
      color: var(--textColor);
    }
  }
`;
export default NavbarMain;
