import React from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { useFaucetContext } from "../context/faucet_context";

const SearchBox = () => {
  const { search_query, searchFaucets } = useFaucetContext();
  // useEffect(() => {
  //   searchThroughFaucets();
  // }, [search_query]);
  return (
    <Wrapper className='container-md mx-auto pt-2 pb-3 px-4'>
      <div className='row justify-content-center' role='search'>
        <div className='col-md-8'>
          <div className='search-form d-flex'>
            <FaSearch className='fa-search-icon' />
            <input
              type='search'
              placeholder='Search for a token name, blockchain, or chain ID...'
              aria-label='Search'
              className='form-control'
              value={search_query}
              onChange={searchFaucets}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .search-form {
    position: relative;
  }
  .search-form input {
    height: 60px;
    text-indent: 2rem;
    border-radius: 25px;
    border: 3px solid var(--searchBoxBorder);
    box-shadow: 5px 5px 0px var(--searchBoxBorder);
  }
  .search-form input:focus {
    /* box-shadow: none; */
    border: 3px solid var(--searchBoxBorderFocus);
    box-shadow: 5px 5px 0px var(--searchBoxBorderFocus);
  }
  .search-form .fa-search-icon {
    position: absolute;
    top: 22px;
    left: 16px;
  }
`;
export default SearchBox;
